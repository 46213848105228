import React from "react";
import { SLOGAN_ADDITIONAL_TEXT, SLOGAN_TEXT } from "@shared/config/GlobalText";
import { iconData } from "./config";
import classes from "./MainPageTitle.module.scss";
import { PlatformData } from "./types";
import { setSource } from "@app/store/filtersSlice";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { RouterConfig } from "@shared/lib/routerConfig";

export interface MainPageTitleProps {}

const MainPageTitle = ({}: MainPageTitleProps) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleIconClick = (item: PlatformData, event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(setSource([item.platformId]));
        router.push(RouterConfig.CATALOG);
    };

    const onClick = (item: PlatformData) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        handleIconClick(item, event);
    };

    return (
        <div className={classes.MainPageTitle}>
            <h1 className={classes.MainPageTitle__text}>{SLOGAN_TEXT}</h1>
            <label className={classes.MainPageTitle__label}>{SLOGAN_ADDITIONAL_TEXT}</label>
            <ul className={classes.MainPageTitle__icons}>
                {iconData.map((item, index) => (
                    <li key={index}>
                        <noindex>
                            <a
                                className={classes["MainPageTitle__icons-link"]}
                                href={item.url}
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                                aria-label={item.label}
                                onClick={onClick(item)}
                            >
                                <item.Icon className={classes.MainPageTitle__item} />
                            </a>
                        </noindex>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default MainPageTitle;